<template>
    <button :type="submit ? 'submit' : 'button'" :class="getButtonClass">
        <span class="loading-container" v-if="props.type === 'loading'">
            <span></span>
            <span></span>
            <span></span>
        </span>
        <span v-else>
            {{ $attrs.title }}
        </span>
    </button>
</template>

<script setup>
    const props = defineProps({
        type: {
            type: String,
            required: false,
        },
        submit: {
            type: Boolean,
            required: false,
        }
    });

    const getButtonClass = computed(() => {
        let className = "c-button";
        if (props.type) {
            className += ` c-button--${props.type}`;
        }
        return className;
    });
</script>
